import React from 'react';
import { useAuthContext } from '@isyc-react/auth/modules/context';
import { namespaces } from 'i18n/i18n.constants';
import { useTranslation } from 'react-i18next';
import { routes } from 'core/router';
import { Redirect } from 'react-router-dom';
import 'dayjs/locale/fr';
import { CardEnrollmentCoursesComponent } from './card/card-enrollment-courses.component';
import { DashboardViewType } from 'common-app/types/core';
import { ListEnrollmentCoursesComponent } from './list';
import { useCardsDashboardEnrollmentCoursesQuery } from 'common/api/queries/enrollment-courses';
import { LoaderDotted } from '@isyc-react/form/modules/base';
import { FormSize } from '@isyc-react/form';
export const DashboardEnrollmentCoursesContainer = props => {
    var _a;
    const { user } = useAuthContext();
    const { t, i18n } = useTranslation(namespaces.common);
    const { currentView, searchCourseName } = props;
    const { data: fullDataEnrolmentCourses, loading, error, } = useCardsDashboardEnrollmentCoursesQuery({ variables: { user_id: (_a = user === null || user === void 0 ? void 0 : user.id) !== null && _a !== void 0 ? _a : 0 } });
    const data = React.useMemo(() => {
        const result = fullDataEnrolmentCourses === null || fullDataEnrolmentCourses === void 0 ? void 0 : fullDataEnrolmentCourses.portal_enrollmentCourses;
        return result;
    }, [fullDataEnrolmentCourses]);
    return (React.createElement(React.Fragment, null, loading ?
        React.createElement("div", { className: "picture-loading mt-5" },
            React.createElement(LoaderDotted, { size: FormSize.LG }))
        : error ?
            React.createElement(Redirect, { to: routes.error })
            : data &&
                React.createElement(React.Fragment, null,
                    React.createElement("div", { id: currentView == DashboardViewType.LIST ? 'list' : 'grid', className: currentView == DashboardViewType.LIST ? 'row bg-white p-5 rounded-lg mb-5 border g-0 mx-2' : 'row' },
                        (data === null || data === void 0 ? void 0 : data.length) > 0 && currentView == DashboardViewType.CARDS
                            && (data === null || data === void 0 ? void 0 : data.filter(name => name.title.toLowerCase().includes(searchCourseName.toLowerCase())).map((course, i) => (React.createElement(CardEnrollmentCoursesComponent, { course: course, key: i })))),
                        (data === null || data === void 0 ? void 0 : data.length) > 0 && currentView == DashboardViewType.LIST
                            && React.createElement(ListEnrollmentCoursesComponent, { list: data === null || data === void 0 ? void 0 : data.filter(name => name.title.toLowerCase().includes(searchCourseName.toLowerCase())) }),
                        (data === null || data === void 0 ? void 0 : data.length) == 0 &&
                            React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "card p-5 d-flex align-items-center my-5 shadow col-11 mx-auto rounded-xxl" },
                                    React.createElement("p", { className: "text-darker-grey font-size-18" }, t('consum.dashboard.mycourses.notfound')),
                                    React.createElement("p", { className: "text-darker-grey mb-0 font-size-18" },
                                        t('consum.dashboard.mycourses.notfound2'),
                                        " ",
                                        React.createElement("b", null,
                                            "\"",
                                            t('consum.dashboard.mycatalogue'),
                                            "\""),
                                        ".")))))));
};
