import React, { useContext } from 'react';
import { NavigationArrows, Tabs } from '@isyc-react/animation/modules';
import { DashboardCoursesToRegistrateContainer } from 'pods/dashboard/dashboard-courses-to-registrate.container';
// import { DashboardEnrollmentCoursesContainer } from 'pods/dashboard/dashboard-enrollment-courses.container'
// import { DashboardFinishedCoursesContainer } from 'pods/dashboard/dashboard-finished-courses.container'
import '../../assets/scss/tabs-dashboard.style.scss';
import clsx from 'clsx';
import { a, useSpring } from '@isyc-react/animation/lib';
import { DashboardTabsType, DashboardViewType } from 'common-app/types/core';
import { useAuthContext } from '@isyc-react/auth/modules/context';
import { SearchComponent } from 'pods/dashboard/search-components';
import { useTranslation } from 'react-i18next';
import { namespaces } from 'i18n/i18n.constants';
import { DashboardEnrollmentCoursesContainer, DashboardFinishedCoursesContainer } from 'pods/dashboard';
import { ThemeContext } from 'core/router/logged';
import { useGetCoursesSizeQuery } from 'common/api/queries/courses-size';
export const TabsDashboard = (props) => {
    var _a;
    const { user } = useAuthContext();
    const { t } = useTranslation(namespaces.common);
    const { currentTab, setCurrentTab } = useContext(ThemeContext);
    const [currentView, setCurrentView] = React.useState(DashboardViewType.CARDS);
    const [currentIdCategoryCoursesToRegistrate, setCurrentIdCategoryCoursesToRegistrate] = React.useState();
    const [currentIdCategoryFinishedCourses, setCurrentIdCategoryFinishedCourses] = React.useState();
    const [searchCourseName, setSearchCourseName] = React.useState('');
    const [sizeEnrollmentCourses, setSizeEnrollmentCourses] = React.useState(0);
    const [sizeCoursesToRegistrate, setSizeCoursesToRegistrate] = React.useState(0);
    const [sizeFinishedCourses, setSizeFinishedCourses] = React.useState(0);
    const { data: fullData, loading, error, refetch } = useGetCoursesSizeQuery({ variables: { user_id: (_a = user === null || user === void 0 ? void 0 : user.id) !== null && _a !== void 0 ? _a : 0 } });
    React.useMemo(() => {
        var _a, _b, _c;
        let result = fullData === null || fullData === void 0 ? void 0 : fullData.portal_courses_size;
        if (result) {
            setSizeEnrollmentCourses((_a = result['Matriculados']) !== null && _a !== void 0 ? _a : 0);
            setSizeCoursesToRegistrate((_b = result['Mi catálogo']) !== null && _b !== void 0 ? _b : 0);
            setSizeFinishedCourses((_c = result['Finalizados']) !== null && _c !== void 0 ? _c : 0);
        }
        return result;
    }, [fullData]);
    const tabs = [
        {
            header: React.createElement(React.Fragment, null,
                t('consum.dashboard.mycourses').toUpperCase(),
                " (",
                sizeEnrollmentCourses,
                ")"),
            content: () => {
                return (React.createElement(React.Fragment, null, !loading &&
                    React.createElement("div", { className: 'info w-100 pb-1' },
                        React.createElement(DashboardEnrollmentCoursesContainer, { searchCourseName: searchCourseName, currentView: currentView }))));
            }
        },
        {
            header: React.createElement(React.Fragment, null,
                t('consum.dashboard.mycatalogue').toUpperCase(),
                " (",
                sizeCoursesToRegistrate,
                ")"),
            content: () => {
                return (React.createElement(React.Fragment, null, !loading &&
                    React.createElement("div", { className: 'info w-100 pb-1' },
                        React.createElement(DashboardCoursesToRegistrateContainer, { refetchSize: refetch, setCurrentView: setCurrentView, setSearchCourseName: setSearchCourseName, currentIdCategory: currentIdCategoryCoursesToRegistrate, setCurrentIdCategory: setCurrentIdCategoryCoursesToRegistrate, searchCourseName: searchCourseName, currentView: currentView, sizeEnrollmentCourses: sizeEnrollmentCourses }))));
            }
        },
        {
            header: React.createElement(React.Fragment, null,
                t('consum.dashboard.finishedcourses').toUpperCase(),
                " (",
                sizeFinishedCourses,
                ")"),
            content: () => {
                return (React.createElement(React.Fragment, null, !loading &&
                    React.createElement("div", { className: 'info w-100 pb-1' },
                        React.createElement(DashboardFinishedCoursesContainer, { setCurrentView: setCurrentView, setSearchCourseName: setSearchCourseName, currentIdCategory: currentIdCategoryFinishedCourses, setCurrentIdCategory: setCurrentIdCategoryFinishedCourses, searchCourseName: searchCourseName, currentView: currentView }))));
            }
        },
    ];
    React.useEffect(() => {
        if (currentTab == DashboardTabsType.MY_CATALOGUE) {
            setCurrentIdCategoryCoursesToRegistrate(null);
        }
        else if (currentTab == DashboardTabsType.FINISHED_COURSES) {
            setCurrentIdCategoryFinishedCourses(null);
        }
    }, [currentTab]);
    const SearchHeader = props => {
        const { children, current } = props;
        const { currentTab, setCurrentTab } = useContext(ThemeContext);
        const containerRef = React.useRef(null);
        const ulRef = React.useRef(null);
        const naRef = React.useRef(null);
        const [indicator, indicatorRef] = useSpring(() => ({
            from: {
                width: 0,
                transform: 'translateX(0px)',
            },
            config: { duration: 200 },
        }));
        const checkButtons = (((currentTab == DashboardTabsType.MY_CATALOGUE && currentIdCategoryCoursesToRegistrate !== null)
            || (currentTab == DashboardTabsType.FINISHED_COURSES && currentIdCategoryFinishedCourses !== null))
            || searchCourseName != '')
            || (currentTab == DashboardTabsType.MY_COURSES);
        const customSetCurrentView = (viewType) => {
            if (checkButtons) {
                setCurrentView(viewType);
            }
        };
        return (React.createElement(NavigationArrows, { watch: containerRef, ref: naRef },
            React.createElement("div", { className: 'tabs-header-container row g-0', ref: containerRef },
                React.createElement("div", { className: 'col p-0' },
                    React.createElement("ul", { className: 'nav', ref: ulRef }, tabs.map((item, tabIndex) => (React.createElement("li", { key: tabIndex, onClick: () => {
                            if (current === undefined)
                                setCurrentTab(tabIndex);
                            else if (setCurrentTab)
                                setCurrentTab(tabIndex);
                        }, className: clsx('nav-item col-12 col-md-auto mb-3 mb-md-0 me-1 me-xl-5 cursor-pointer cursor-pointer', {
                            'cursor-pointer': tabIndex !== currentTab
                        }) },
                        React.createElement("a", { className: clsx('nav-link', {
                                active: tabIndex === currentTab,
                            }) }, item.header)))))),
                React.createElement("div", { className: 'col-xxl-auto d-none d-xxl-block' },
                    React.createElement(SearchComponent, { setSearchCourseName: setSearchCourseName, searchCourseName: searchCourseName })),
                React.createElement("div", { className: 'col-xxl-auto buttons mb-2 mb-lg-0 d-none d-xxl-block' },
                    React.createElement("i", { className: clsx('mdi mdi-format-list-bulleted text-primary font-size-32 me-2', {
                            'cursor-pointer': currentView !== DashboardViewType.LIST && checkButtons
                        }), onClick: () => { customSetCurrentView(DashboardViewType.LIST); } }),
                    React.createElement("i", { className: clsx('mdi mdi-apps text-primary font-size-32', {
                            'cursor-pointer': currentView !== DashboardViewType.CARDS && checkButtons
                        }), onClick: () => { customSetCurrentView(DashboardViewType.CARDS); } }))),
            React.createElement("div", { className: 'tabs-active-item-indicator-wrapper' },
                React.createElement(a.div, { className: 'tabs-active-item-indicator', style: indicator }))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'tabs' },
            React.createElement(Tabs, { current: currentTab, onChange: (current) => setCurrentTab(current), className: 'tabs-dashboard', components: { Header: SearchHeader }, tabs: tabs }))));
};
