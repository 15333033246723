import { gql, useMutation } from '@apollo/client';
export const REGISTRATION_TO_COURSE = gql `
  mutation RegistrationToCourse($user_id: Long!, $sessionId: Long!) {
    portal_registrationToCourse(user_id: $user_id, session_id: $sessionId)
  }
`;
export const useRegistrationToCourse = (onCompleteQuery) => {
    return useMutation(REGISTRATION_TO_COURSE, {
        errorPolicy: 'all',
        onCompleted({ portal_registrationToCourse }) {
            if (onCompleteQuery) {
                onCompleteQuery(portal_registrationToCourse);
            }
        },
    });
};
