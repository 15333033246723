import React from 'react';
import { LoginLayout } from 'layout';
import { LoginForm } from 'pods/login';
import { useParams } from 'react-router-dom';
import { useTransition } from '@isyc-react/animation/lib';
import { LoginView } from '@isyc-react/auth/modules/pods/login/login.vm';
export const LoginScene = () => {
    const { code } = useParams();
    const [view, setView] = React.useState(LoginView.LOGIN_FORM);
    const [restore, setRestore] = React.useState(true);
    const buCode = React.useMemo(() => { var _a; return (_a = code === null || code === void 0 ? void 0 : code.toUpperCase()) !== null && _a !== void 0 ? _a : 'SHARE'; }, [code]);
    React.useEffect(() => {
        localStorage.setItem('buCode', buCode);
    }, [buCode]);
    const pictureColors = [
        'bg-blue',
        'bg-green',
        'bg-yellow',
        'bg-orange',
        'bg-red',
        'bg-brown',
        'bg-pink',
        'bg-purple',
        'bg-grey',
    ];
    React.useEffect(() => {
        const random = Math.floor(Math.random() * pictureColors.length);
        localStorage.setItem('pictureColor', pictureColors[random]);
    }, []);
    const transitions = useTransition(view, {
        from: { opacity: 0, transform: 'translate3d(0,40px,0)', position: 'static' },
        enter: { opacity: 1, transform: 'translate3d(0,0px,0)', position: 'static' },
        leave: { opacity: 0, position: 'absolute', width: '25%' },
        config: { mass: 5, tension: 2000, friction: 200 },
    });
    return (React.createElement(LoginLayout, null,
        React.createElement(LoginForm, { restore: false, labels: true, onSuccessRedirectInitialLocation: true })));
};
