import { gql, useApolloClient, useQuery } from '@apollo/client';
export const GET_COURSES_SIZE = gql `
  query GetCoursesSize($user_id: Long!) {
    portal_courses_size(user_id: $user_id)
  }
`;
export const useGetCoursesSizeQuery = (options) => {
    return useQuery(GET_COURSES_SIZE, Object.assign({ errorPolicy: 'ignore', fetchPolicy: 'cache-first' }, options));
};
export const useGetCoursesSizeQueryPromise = () => {
    const client = useApolloClient();
    return (variables, options) => client.query(Object.assign(Object.assign({}, options), { query: GET_COURSES_SIZE, variables }));
};
