import React, { createContext } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { switchRoutes } from 'core/router/router';
import { ErrorScene, DashboardScene } from 'scenes';
import { DashboardTabsType } from 'common-app/types/core';
const LoggedRouterRedirect = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Redirect, { to: switchRoutes.courses })));
};
export const ThemeContext = createContext({
    currentTab: DashboardTabsType.MY_COURSES,
    setCurrentTab: (number) => { }
});
export const RouterLogged = () => {
    const location = useLocation();
    const [currentTab, setCurrentTab] = React.useState(DashboardTabsType.MY_COURSES);
    return (React.createElement(ThemeContext.Provider, { value: { currentTab, setCurrentTab } },
        React.createElement(Switch, { location: location },
            React.createElement(Route, { path: [switchRoutes.courses] },
                React.createElement(DashboardScene, null)),
            React.createElement(Route, { path: [switchRoutes.error] },
                React.createElement(ErrorScene, null)),
            React.createElement(Route, { path: '*' },
                React.createElement(LoggedRouterRedirect, null)))));
};
