import { gql, useApolloClient, useQuery } from '@apollo/client';
export const GET_LINK_ACCESS_MOODLE = gql `
  query GetLinkAccessMoodle($moodle_id: Long!) {
    portal_accessMoodle(moodle_id: $moodle_id)
  }
`;
export const useGetLinkAccessMoodleQuery = (options) => {
    return useQuery(GET_LINK_ACCESS_MOODLE, Object.assign({ errorPolicy: 'ignore', fetchPolicy: 'network-only' }, options));
};
export const useGetLinkAccessMoodleQueryPromise = () => {
    const client = useApolloClient();
    return (variables, options) => client.query(Object.assign(Object.assign({ errorPolicy: 'all', fetchPolicy: 'network-only' }, options), { query: GET_LINK_ACCESS_MOODLE, variables }));
};
