import React from 'react';
import 'dayjs/locale/fr';
import { useTranslation } from 'react-i18next';
import { namespaces } from 'i18n/i18n.constants';
import imgCourse from 'assets/img/img-course.jpg';
import { ModalInfoComponent } from './../modal/modal-info.component';
import { useGetLinkAccessMoodleQueryPromise } from 'common/api/queries/access-to-moodle';
import dayjs from 'dayjs';
import { useGetPictureQuery } from 'common/api/queries';
import { Spinner } from '@isyc-react/design/bootstrap';
export const CardFinishedCoursesComponent = (props) => {
    var _a;
    const { course } = props;
    const { t, i18n } = useTranslation(namespaces.common);
    const [modalInfoShow, setModalInfoShow] = React.useState(false);
    const getLinkAccessMoodle = useGetLinkAccessMoodleQueryPromise();
    const accessMoodle = (moodle_id) => {
        getLinkAccessMoodle({ moodle_id }).then((response) => {
            if (response.data && response.data.portal_accessMoodle != "") {
                window.open(response.data.portal_accessMoodle, '_blank', 'noopener,noreferrer');
            }
        });
    };
    const imagenCurso = course.imagenCurso ? course.imagenCurso : 0;
    let dataPictureBase64 = imgCourse;
    var loadingPicture = false;
    if (imagenCurso !== 0) {
        var { data: dataPicture, loading: loadingPicture, error: errorPicture, refetch: refetchPicture } = useGetPictureQuery({ variables: { archive_id: +imagenCurso } });
        dataPictureBase64 = React.useMemo(() => {
            return (dataPicture === null || dataPicture === void 0 ? void 0 : dataPicture.portal_getCoursePicture) != '' ? dataPicture === null || dataPicture === void 0 ? void 0 : dataPicture.portal_getCoursePicture : imgCourse;
        }, [dataPicture]);
    }
    const checkInfo = course.description != "" || course.goals != "";
    const qualification = course.qualification;
    const qualificationColor = course.estadoCurso == 13 ? 'green' : 'red';
    const estadoCursoColor = course.estadoCurso == 13 ? 'text-green' : 'text-red';
    const languagePortal = i18n.language;
    const showValenciano = (estado) => {
        switch (estado) {
            case "Inscrito":
                return "Inscrit";
            case "Completado":
                return "Finalitzat";
            case "No asistió":
                return "No assistit";
            case "Incompleta":
                return "Incompleta";
            case "Cancelada":
                return "Cancelada";
            case "No superado":
                return "No superat";
            default:
                return course.estadoCursoLabel;
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "col-xxl-4 col-md-6 d-flex" },
            React.createElement("div", { className: "card mb-4 col-12" },
                React.createElement("div", { className: "card-body p-0" },
                    React.createElement("div", { className: "container-picture" },
                        React.createElement("div", { className: "above" },
                            React.createElement("div", { className: "score " + qualificationColor + " p-1 row d-flex text-center align-items-center" },
                                React.createElement("span", { className: "font-size-21" }, qualification && qualification.toString() != "" ? qualification : '-')),
                            React.createElement("div", { className: "text-in-picture h-100 d-flex justify-content-end flex-column d-flex p-3" },
                                React.createElement("p", { className: "text-white mb-2 font-size-12 fw-semibold" }, course.category),
                                React.createElement("p", { className: "text-white font-size-19 fw-semibold mb-0" }, course.title))),
                        loadingPicture
                            ? React.createElement("div", { className: "picture-loading", style: { backgroundImage: 'linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.6))' } },
                                React.createElement(Spinner, { animation: 'border', className: 'ms-2 text-white' }))
                            : React.createElement("div", { className: "picture", style: { backgroundImage: 'linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.6)),url("' + dataPictureBase64 + '")' } })),
                    React.createElement("div", { className: "info px-4 pt-4" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col" },
                                React.createElement("i", { className: "mdi mdi-clock-outline text-primary bg-lighter-grey px-2 py-1 font-size-16 me-2" }),
                                React.createElement("span", { className: "font-size-14" },
                                    course.duration,
                                    " h")),
                            React.createElement("div", { className: "col-auto" },
                                React.createElement("span", { className: "font-size-14 " + estadoCursoColor }, languagePortal === "VL" ? showValenciano(course.estadoCursoLabel) : course.estadoCursoLabel))),
                        React.createElement("div", { className: "text-center more-info" },
                            checkInfo ?
                                React.createElement("a", { className: "text-dark-grey font-size-14 cursor-pointer", onClick: () => setModalInfoShow(true) },
                                    React.createElement("u", null, t('consum.dashboard.moreinformation')))
                                :
                                    React.createElement("p", { className: "text-dark-grey font-size-14" }, t('consum.dashboard.noinformation')),
                            React.createElement("div", null,
                                React.createElement("span", { className: "font-size-14 text-green" },
                                    t('consum.dashboard.finishedcourses.finaldate'),
                                    " ",
                                    dayjs((_a = course.finalDate) !== null && _a !== void 0 ? _a : new Date()).format('DD/MM/YYYY')))),
                        React.createElement("div", { className: "separe border-bottom" }))),
                React.createElement("div", { className: "card-footer w-100 d-flex justify-content-center bg-white border-0 my-4" },
                    React.createElement("button", { type: "button", onClick: () => accessMoodle(course.moodleLink), className: "btn btn-pill btn-outline-primary border-2 px-5 font-size-16" }, t('consum.dashboard.finishedcourses.access'))))),
        React.createElement(ModalInfoComponent, { show: modalInfoShow, onHide: () => setModalInfoShow(false), course: course })));
};
