import React, { useContext } from 'react';
import { useAuthContext } from '@isyc-react/auth/modules/context';
import { namespaces } from 'i18n/i18n.constants';
import { useTranslation } from 'react-i18next';
import { routes } from 'core/router';
import { Redirect } from 'react-router-dom';
import 'dayjs/locale/fr';
import { CardCoursesToRegistrateComponent } from './card/card-courses-to-registrate.component';
import { DashboardViewType } from 'common-app/types/core';
import { ListCoursesToRegistrateComponent } from './list';
import { CardCategoriesComponent } from './card/card-categories.component';
import { useCardsDashboardCoursesToRegistrateQuery } from 'common/api/queries';
import { HeaderCategoryComponent } from './header-category';
import { LoaderDotted } from '@isyc-react/form/modules/base';
import { FormSize } from '@isyc-react/form';
import { ThemeContext } from 'core/router/logged';
export const DashboardCoursesToRegistrateContainer = props => {
    var _a;
    const { user } = useAuthContext();
    const { setCurrentTab } = useContext(ThemeContext);
    const { currentView, searchCourseName, setSearchCourseName, currentIdCategory, setCurrentIdCategory, setCurrentView, refetchSize, sizeEnrollmentCourses } = props;
    const { t, i18n } = useTranslation(namespaces.common);
    const [loadingRefresh, setLoadingRefresh] = React.useState(false);
    const { data: fullDataCoursesToRegistrate, client, loading, error, refetch } = useCardsDashboardCoursesToRegistrateQuery({ variables: { user_id: (_a = user === null || user === void 0 ? void 0 : user.id) !== null && _a !== void 0 ? _a : 0 } });
    const data = React.useMemo(() => {
        let result = fullDataCoursesToRegistrate === null || fullDataCoursesToRegistrate === void 0 ? void 0 : fullDataCoursesToRegistrate.portal_categorizedCoursesToRegistrate;
        return result;
    }, [fullDataCoursesToRegistrate]);
    const isViewCategory = (currentIdCategory == null && searchCourseName == '');
    const checkCurrentIdCategory = (currentIdCategory ? currentIdCategory : '');
    const resetView = () => {
        setCurrentIdCategory && setCurrentIdCategory(null);
        setSearchCourseName && setSearchCourseName('');
    };
    React.useEffect(() => {
        if (isViewCategory) {
            setCurrentView && setCurrentView(DashboardViewType.CARDS);
        }
    }, [searchCourseName, currentIdCategory]);
    const refresh = () => {
        client.clearStore().finally(() => {
            refetchSize && refetchSize();
            refetch();
        });
    };
    return (React.createElement(React.Fragment, null, (loading || loadingRefresh) ?
        React.createElement("div", { className: "picture-loading mt-5" },
            React.createElement(LoaderDotted, { size: FormSize.LG }))
        : error ?
            React.createElement(Redirect, { to: routes.error })
            : data &&
                React.createElement(React.Fragment, null,
                    !isViewCategory &&
                        React.createElement(HeaderCategoryComponent, { currentIdCategory: currentIdCategory, setCurrentIdCategory: setCurrentIdCategory, resetView: resetView, data: data, searchCourseName: searchCourseName }),
                    React.createElement("div", { id: currentView == DashboardViewType.LIST ? 'list' : 'grid', className: currentView == DashboardViewType.LIST ? 'row bg-white p-5 rounded-lg mb-5 border g-0 mx-2' : 'row' },
                        isViewCategory &&
                            React.createElement("div", { className: 'row mb-5' },
                                React.createElement("div", { className: 'col-md' },
                                    React.createElement("h2", { className: 'text-primary p-1 border-primary' }, t('consum.dashboard.category.title')))),
                        isViewCategory && (data === null || data === void 0 ? void 0 : data.length) > 0 &&
                            (data === null || data === void 0 ? void 0 : data.map((item, i) => React.createElement(CardCategoriesComponent, { idCategory: item.idCategory, category: item.category, imagenCategory: item.imagenCurso, categorySize: item.size, key: i, setCurrentIdCategory: setCurrentIdCategory }))),
                        !isViewCategory && currentView == DashboardViewType.CARDS && (data === null || data === void 0 ? void 0 : data.length) > 0 && (data === null || data === void 0 ? void 0 : data.filter(name => (checkCurrentIdCategory != '' ? name.idCategory == checkCurrentIdCategory : true)).map((courses, i) => courses.portalCourseDTOList.filter(name => name.title.toLowerCase().includes(searchCourseName.toLowerCase())).map((course, i2) => {
                            return React.createElement(CardCoursesToRegistrateComponent, { course: course, key: i2, refresh: refresh, sizeEnrollmentCourses: sizeEnrollmentCourses });
                        }))),
                        !isViewCategory && currentView == DashboardViewType.LIST && (data === null || data === void 0 ? void 0 : data.length) > 0 && (data === null || data === void 0 ? void 0 : data.filter(name => (checkCurrentIdCategory != '' ? name.idCategory == checkCurrentIdCategory : true)).map((courses, i) => React.createElement(ListCoursesToRegistrateComponent, { list: courses.portalCourseDTOList.filter(name => name.title.toLowerCase().includes(searchCourseName.toLowerCase())), refresh: refresh, sizeEnrollmentCourses: sizeEnrollmentCourses }))),
                        (data === null || data === void 0 ? void 0 : data.length) == 0 &&
                            React.createElement("div", { className: "card p-5 d-flex align-items-center my-5 shadow col-11 mx-auto rounded-xxl" },
                                React.createElement("p", { className: "text-darker-grey mb-0 font-size-18" }, t('consum.dashboard.mycatalogue.notfound'))),
                        !isViewCategory &&
                            React.createElement("div", { className: 'row mb-5' },
                                React.createElement("div", { className: 'col-md text-primary fw-bold' },
                                    React.createElement("a", { style: { textDecoration: 'underline' }, className: 'nav-link cursor-pointer', onClick: () => { resetView(); } },
                                        '< ',
                                        t('consum.dashboard.mycatalogue.returncategories'))))))));
};
