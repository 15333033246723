import React from 'react';
import { AuthStatus, useAuthContext } from '@isyc-react/auth/modules/context';
import { BrowserRouter } from 'react-router-dom';
import { RouterLogged } from './logged';
import { RouterUnlogged } from './unlogged';
const basename = '/consum';
export const Router = () => {
    const { status } = useAuthContext();
    const AuthRouter = status === AuthStatus.LOGGED ? RouterLogged : RouterUnlogged;
    return (React.createElement(BrowserRouter, { basename: basename }, status === AuthStatus.LOADING ? (React.createElement("div", { className: 'd-flex align-items-center justify-content-center' },
        React.createElement("div", { className: 'spinner-border text-secondary mt-5', role: 'status', style: { width: '40px', height: '40px' } },
            React.createElement("span", { className: 'visually-hidden' }, "Loading...")))) : (React.createElement(React.Fragment, null,
        React.createElement(AuthRouter, null)))));
};
