import React from 'react';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';
import { Button, Modal, Spinner } from '@isyc-react/design/bootstrap';
import { useRegistrationToCourse } from 'common/api/queries/registration-to-course';
import { useAuthContext } from '@isyc-react/auth/modules/context';
import icoModal from 'assets/img/ico-modal.png';
import { useTranslation } from 'react-i18next';
import { namespaces } from 'i18n/i18n.constants';
import { AlertType } from 'common-app/types/core';
export const ModalDatesComponent = (props) => {
    const { sessions = [], refresh, sizeEnrollmentCourses } = props;
    const { t } = useTranslation(namespaces.common);
    const { user } = useAuthContext();
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertType, setAlertType] = React.useState('');
    const [alertMsg, setAlertMsg] = React.useState('');
    const [registrationToCourse, { data, loading, error }] = useRegistrationToCourse();
    const registration = (userId, sessionId) => {
        registrationToCourse({ variables: { user_id: userId, sessionId: sessionId } }).then((d) => {
            var _a, _b, _c, _d;
            if (((_a = d.data) === null || _a === void 0 ? void 0 : _a.portal_registrationToCourse) == "OK") {
                setShowAlert(true);
                setAlertType(AlertType.SUCCESS);
                setAlertMsg(t('consum.dashboard.mycourses.ok'));
            }
            else if (((_b = d.data) === null || _b === void 0 ? void 0 : _b.portal_registrationToCourse) == "KO") {
                setShowAlert(true);
                setAlertType(AlertType.DANGER);
                setAlertMsg(t('consum.dashboard.mycourses.ko'));
            }
            else if (((_c = d.data) === null || _c === void 0 ? void 0 : _c.portal_registrationToCourse) == "SESSION KO") {
                setShowAlert(true);
                setAlertType(AlertType.DANGER);
                setAlertMsg(t('consum.dashboard.mycourses.sessionko'));
            }
            else if (((_d = d.data) === null || _d === void 0 ? void 0 : _d.portal_registrationToCourse) == "KO EXIST ENROLLMENT") {
                setShowAlert(true);
                setAlertType(AlertType.DANGER);
                setAlertMsg(t('consum.dashboard.mycourses.existenrollment'));
            }
        });
    };
    const closeModal = () => {
        if (showAlert) {
            refresh();
        }
        setShowAlert(false);
        props.onHide();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { show: props.show, size: "lg", "aria-labelledby": "contained-modal-title-vcenter", centered: true },
            React.createElement(Modal.Header, { className: 'border-bottom-0 bg-white p-4' },
                React.createElement("h5", { className: 'modal-title font-size-22 text-black' }, t('consum.modaldates.availabledates')),
                React.createElement("button", { type: "button", className: "btn-close me-1", "aria-label": t('consum.modaldates.close'), onClick: closeModal })),
            React.createElement(Modal.Body, { className: 'p-0' },
                React.createElement("div", { className: 'row m-0' },
                    React.createElement("div", { className: 'col px-5 pb-4' },
                        !showAlert && sessions && sessions.length > 0 &&
                            React.createElement("table", { className: 'table' },
                                React.createElement("thead", null,
                                    React.createElement("tr", null,
                                        React.createElement("th", null, t('consum.modaldates.startdate')),
                                        React.createElement("th", null, t('consum.modaldates.finaldate')),
                                        React.createElement("th", null))),
                                React.createElement("tbody", null, sessions.map((session, i) => {
                                    var _a, _b;
                                    return (React.createElement("tr", { key: session.ID },
                                        React.createElement("td", null, dayjs((_a = session.initialDate) !== null && _a !== void 0 ? _a : '').format('DD/MM/YYYY')),
                                        React.createElement("td", null, dayjs((_b = session.finalDate) !== null && _b !== void 0 ? _b : '').format('DD/MM/YYYY')),
                                        React.createElement("td", null,
                                            sizeEnrollmentCourses == 0 &&
                                                React.createElement("button", { type: "button", onClick: () => { var _a; return registration((_a = user === null || user === void 0 ? void 0 : user.id) !== null && _a !== void 0 ? _a : 0, session.ID); }, className: "btn btn-primary btn-sm btn-pill", disabled: loading }, t('consum.modaldates.enrol')),
                                            sizeEnrollmentCourses > 0 &&
                                                React.createElement("p", { className: "text-center m-0" }, t('consum.modaldates.noavailable')),
                                            loading && React.createElement(Spinner, { animation: 'border', className: 'ms-2', size: 'sm' }))));
                                }))),
                        showAlert &&
                            React.createElement("p", { className: "text-center " + alertType }, alertMsg)),
                    React.createElement("div", { className: 'col-auto align-self-end p-0 d-none d-lg-block' },
                        React.createElement("img", { src: icoModal })))),
            React.createElement(Modal.Footer, { className: 'd-flex justify-content-center py-4 mt-5 mt-lg-0' },
                React.createElement(Button, { className: 'btn btn-pill btn-primary border-2 px-5 font-size-16', onClick: closeModal }, t('consum.modaldates.close'))))));
};
