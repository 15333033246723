var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import { RhfFieldError, useController, useRhfTransformInterpolation } from '@isyc-react/form/modules/rhf';
import clsx from 'clsx';
import { FormSize } from '@isyc-react/form';
export const InputPassword = (props) => {
    const { onFocus, onBlur, onChange, error, size } = props, rest = __rest(props, ["onFocus", "onBlur", "onChange", "error", "size"]);
    const [focused, setFocused] = React.useState(false);
    const ref = React.useRef(null);
    //TOGGLE EYE
    const [passwordType, setPasswordType] = useState("password");
    const [passwordIconEye, setPasswordIconEye] = useState(""); //default
    const togglePassword = (visible) => {
        if (visible) {
            setPasswordType("text");
            setPasswordIconEye("active");
        }
        else {
            setPasswordType("password");
            setPasswordIconEye("");
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("input", Object.assign({ ref: ref, className: clsx('form-control', {
                'form-control-sm': size === FormSize.SM,
                'form-control-lg': size === FormSize.LG,
                'is-invalid': error,
            }), onChange: e => {
                onChange && onChange(e.target.value, e);
            }, onFocus: onFocus, onBlur: onBlur, type: passwordType }, rest)),
        React.createElement("div", { className: 'icon' },
            React.createElement("i", { className: "mdi mdi-eye " + passwordIconEye, onClick: () => togglePassword(true) }),
            React.createElement("i", { className: "mdi mdi-eye-off " + passwordIconEye, onClick: () => togglePassword(false) }))));
};
export const RhfInputPassword = props => {
    const { rhf, errorMessage, input, transform } = props;
    const { field: { onChange, onBlur, name, value, ref }, fieldState: { error }, } = useController(rhf);
    const interpolation = useRhfTransformInterpolation(value, transform);
    return (React.createElement(React.Fragment, null,
        React.createElement(InputPassword, Object.assign({ onChange: (c, e) => {
                const change = e ? interpolation(e) : c;
                onChange(change);
            }, onBlur: onBlur, value: value, error: Boolean(error) }, input)),
        React.createElement(RhfFieldError, { errorMessage: errorMessage, error: error })));
};
