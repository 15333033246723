// import React from 'react'
// import { Spinner } from '@isyc-react/design/bootstrap'
// import { useTranslation } from 'react-i18next'
// import { nsauth } from 'i18n/namespaces'
// import { LoginActionStatus, LoginButtonConfig } from './login.vm'
// import { useLogin } from './use-login.hook'
// import { SubmitHandler, useForm } from '@isyc-react/form/modules/rhf'
// import { RhfInputText } from '@isyc-react/form/modules/input/text'
// import { RhfInputPassword } from '@isyc-react/form/modules/input/password'
// import { User } from '@isyc-react/types'
// import { Slug } from '@isyc-react/animation'
// import { FormSize } from '@isyc-react/form/view-model'
// import { AxiosError } from 'axios'
// import { AuthErrorCode, AuthErrorData, LoginInputForm } from '@isyc-react/oc-api/auth'
// import { getCheckPwdRoute } from 'modules/base'
// import clsx from 'clsx'
// import { useHistory, useLocation } from 'react-router-dom'
import React from 'react';
import { Slug } from '@isyc-react/animation';
import { getCheckPwdRoute } from '@isyc-react/auth/modules/base';
import { AuthErrorCode } from '@isyc-react/oc-api/auth';
import { useLogin } from '@isyc-react/auth/modules/pods/login';
import { LoginActionStatus } from '@isyc-react/auth/modules/pods/login/login.vm';
import { useForm } from '@isyc-react/form/modules/rhf';
import { FormSize } from '@isyc-react/form';
import { Spinner } from '@isyc-react/design/bootstrap';
import { RhfInputText } from '@isyc-react/form/modules/input/text';
import { useHistory, useLocation } from 'react-router-dom';
import { namespaces } from 'i18n/i18n.constants';
import { useTranslation } from 'react-i18next';
import { nsauth } from '@isyc-react/auth/i18n/namespaces';
import clsx from 'clsx';
import { RhfInputPassword } from 'common/input';
const loginFormButtonDefault = {
    className: 'btn-lg',
    label: () => {
        const { t } = useTranslation(nsauth.common);
        return t('auth.login.login');
    },
};
export const LoginForm = props => {
    const { onSuccess, onError, onRestore, restore = true, labels = true, button = loginFormButtonDefault, onSuccessRedirectInitialLocation = true, buCode, } = props;
    const { label: ButtonLabel = loginFormButtonDefault.label, className: buttonClassName = loginFormButtonDefault.className, } = button;
    const [status, setStatus] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const { login } = useLogin();
    const { t } = useTranslation([nsauth.common, namespaces.common]);
    const { search } = useLocation();
    const history = useHistory();
    const { control, handleSubmit } = useForm({
        mode: 'onSubmit',
        defaultValues: {
            username: '',
            password: '',
        },
    });
    const buCodeUri = React.useMemo(() => {
        const params = new URLSearchParams(search);
        const buCodeParam = params.get('buCode');
        return buCodeParam ? decodeURIComponent(buCodeParam) : 'SHARE';
    }, [search]);
    const onSubmit = form => {
        setLoading(true);
        const bu = buCode !== null && buCode !== void 0 ? buCode : buCodeUri;
        login(Object.assign(Object.assign({}, form), { bu }), onSuccessRedirectInitialLocation)
            .then(res => {
            setLoading(false);
            setStatus(LoginActionStatus.SUCCESS);
            onSuccess && onSuccess(res.user);
        })
            .catch((e) => {
            var _a;
            // TODO show different errors checking error code (FEEDBACK)
            setLoading(false);
            if (e.response && e.response.data.code === AuthErrorCode.USER_CHANGE_PWD_ERROR) {
                history.push(getCheckPwdRoute(form.username, bu));
            }
            setStatus(LoginActionStatus.ERROR);
            onError && onError((_a = e.response) === null || _a === void 0 ? void 0 : _a.data);
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
            React.createElement(Slug, { from: { opacity: 0, transform: 'translate3d(0,40px,0)' } },
                React.createElement("div", { className: 'form-floating' },
                    React.createElement(RhfInputText, { rhf: { control, name: 'username', rules: { required: true } }, input: {
                            size: FormSize.LG,
                            placeholder: t('auth.login.user'),
                        }, errorMessage: t('common:consum.login.required') }),
                    React.createElement("label", { htmlFor: "username", className: 'form-label' }, t('auth.login.user'))),
                React.createElement("div", { className: 'form-floating password-container' },
                    React.createElement(RhfInputPassword, { rhf: { control, name: 'password', rules: { required: true } }, input: {
                            size: FormSize.LG,
                            placeholder: t('auth.login.password'),
                        }, errorMessage: t('common:consum.login.required') }),
                    React.createElement("label", { htmlFor: "password", className: 'form-label' }, t('auth.login.password'))),
                status && status === LoginActionStatus.ERROR && (React.createElement(Slug, null,
                    React.createElement("p", { className: 'text-danger text-center' }, t('common:consum.login.error')))),
                React.createElement("button", { className: clsx('btn btn-login btn-lg mx-auto d-block enter', buttonClassName), type: 'submit', disabled: loading },
                    typeof ButtonLabel === 'function' ? React.createElement(ButtonLabel, null) : ButtonLabel,
                    loading && React.createElement(Spinner, { animation: 'border', className: 'ms-2', size: 'sm' })),
                restore && (React.createElement("p", { className: 'text-center mt-4' },
                    React.createElement("span", { className: 'cursor-pointer', onClick: () => onRestore && onRestore() }, t('auth.login.lostPassword'))))))));
};
