import React from 'react';
import logo from 'assets/img/login-logo.png';
import '../../assets/scss/login.layout.style.scss';
import { useTranslation } from 'react-i18next';
import { namespaces } from 'i18n/i18n.constants';
export const LoginLayout = props => {
    const { children } = props;
    const { t } = useTranslation(namespaces.common);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'login' },
            React.createElement("main", { className: "d-flex justify-content-center flex-column" },
                React.createElement("div", { className: 'logo text-center' },
                    React.createElement("img", { src: logo, className: 'mw-100' })),
                React.createElement("div", { className: "m-5" },
                    React.createElement("div", { className: "card-header bg-transparent border-0 text-center" },
                        React.createElement("h2", { className: "w-100 text-center" }, t('consum.login.title'))),
                    React.createElement("div", { className: "card-body" }, children))),
            React.createElement("footer", null,
                React.createElement("p", { className: "mb-0 mr-4" }, "Version: 1.0 - Consum")))));
};
