import React from 'react';
import 'dayjs/locale/fr';
import { useTranslation } from 'react-i18next';
import { namespaces } from 'i18n/i18n.constants';
export const HeaderCategoryComponent = (props) => {
    const { currentIdCategory, setCurrentIdCategory, resetView, data, searchCourseName } = props;
    const { t } = useTranslation(namespaces.common);
    const [name, setName] = React.useState('');
    const title = searchCourseName == '' && currentIdCategory != '' ? name : t('consum.dashboard.category.results');
    React.useEffect(() => {
        data === null || data === void 0 ? void 0 : data.filter(name => name.idCategory == currentIdCategory).map((courses, i) => {
            setName(courses.category + " (" + courses.size + ")");
        });
    }, [currentIdCategory]);
    return (React.createElement("div", { className: 'row mb-5' },
        React.createElement("div", { className: 'col-md' },
            React.createElement("a", { className: 'nav-link cursor-pointer', onClick: () => { resetView(); } },
                React.createElement("h2", { className: 'text-primary p-1 border-primary' }, title))),
        searchCourseName == '' &&
            React.createElement("div", { className: 'col-md-auto' },
                React.createElement("select", { className: "form-select form-select-lg pe-5", onChange: (e) => { var _a; setCurrentIdCategory && setCurrentIdCategory((_a = e.target.value) !== null && _a !== void 0 ? _a : null); } },
                    React.createElement("option", { value: 0 }, t('consum.dashboard.category.select')), data === null || data === void 0 ? void 0 :
                    data.map((item, tabIndex) => (React.createElement("option", { id: item.idCategory, selected: item.idCategory === currentIdCategory, key: tabIndex, value: item.idCategory }, item.category)))))));
};
