import { gql, useQuery } from '@apollo/client';
import { CARD_DASHBOARD_ENROLLMENT_COURSES } from '../apollo';
export const GET_CARDS_ENROLLMENT_COURSES = gql `
  ${CARD_DASHBOARD_ENROLLMENT_COURSES}
  query GetEnrollmentCourses($user_id: Long!) {
    portal_enrollmentCourses(user_id: $user_id) {
      ...EnrollmentCourses
    }
  }
`;
export const useCardsDashboardEnrollmentCoursesQuery = (options) => {
    return useQuery(GET_CARDS_ENROLLMENT_COURSES, Object.assign({ errorPolicy: 'ignore', fetchPolicy: 'cache-first', notifyOnNetworkStatusChange: true }, options));
};
