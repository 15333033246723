import React from 'react';
import { SidebarLayout } from 'layout';
import { HeaderDashboard } from './header-dashboard';
import { TabsDashboard } from './tabs-dashboard';
import '../../assets/scss/dashboard.style.scss';
import { useAuthContext } from '@isyc-react/auth/modules/context';
export const DashboardScene = (props) => {
    const { user } = useAuthContext();
    return (React.createElement(SidebarLayout, { footer: true },
        React.createElement(HeaderDashboard, null),
        React.createElement(TabsDashboard, null)));
};
