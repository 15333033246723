import React from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { LoginScene } from 'scenes';
import { routes, switchRoutes } from 'core/router/router';
const RouterUnloggedRedirect = () => {
    const buCode = React.useMemo(() => { var _a; return (_a = localStorage.getItem('buCode')) !== null && _a !== void 0 ? _a : 'share'; }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Redirect, { to: routes.bu(buCode) })));
};
export const RouterUnlogged = () => {
    const location = useLocation();
    return (React.createElement(Switch, { location: location },
        React.createElement(Route, { exact: true, path: [switchRoutes.bu] },
            React.createElement(LoginScene, null)),
        React.createElement(Route, { path: '*' },
            React.createElement(RouterUnloggedRedirect, null))));
};
