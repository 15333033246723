import React from 'react';
import { useAuthContext } from '@isyc-react/auth/modules/context';
import { namespaces } from 'i18n/i18n.constants';
import { useTranslation } from 'react-i18next';
import { routes } from 'core/router';
import { Redirect } from 'react-router-dom';
import 'dayjs/locale/fr';
import { CardFinishedCoursesComponent } from './card/card-finished-courses.component';
import { DashboardViewType } from 'common-app/types/core';
import { ListFinishedCoursesComponent } from './list';
import { useCardsDashboardFinishedCoursesQuery } from 'common/api/queries/finished-courses';
import { CardCategoriesComponent } from './card/card-categories.component';
import { HeaderCategoryComponent } from './header-category';
import { LoaderDotted } from '@isyc-react/form/modules/base';
import { FormSize } from '@isyc-react/form';
export const DashboardFinishedCoursesContainer = props => {
    var _a;
    const { user } = useAuthContext();
    const { t, i18n } = useTranslation(namespaces.common);
    const { currentView, searchCourseName, setSearchCourseName, currentIdCategory, setCurrentIdCategory, setCurrentView } = props;
    const { data: fullDataFinishedCourses, loading, error, refetch } = useCardsDashboardFinishedCoursesQuery({ variables: { user_id: (_a = user === null || user === void 0 ? void 0 : user.id) !== null && _a !== void 0 ? _a : 0 } });
    const data = React.useMemo(() => {
        const result = fullDataFinishedCourses === null || fullDataFinishedCourses === void 0 ? void 0 : fullDataFinishedCourses.portal_categorizedFinishedCourses;
        return result;
    }, [fullDataFinishedCourses]);
    const isViewCategory = (currentIdCategory == null && searchCourseName == '');
    const checkCurrentIdCategory = (currentIdCategory ? currentIdCategory : '');
    const resetView = () => {
        setCurrentIdCategory && setCurrentIdCategory(null);
        setSearchCourseName && setSearchCourseName('');
    };
    React.useEffect(() => {
        if (isViewCategory) {
            setCurrentView && setCurrentView(DashboardViewType.CARDS);
        }
    }, [searchCourseName, currentIdCategory]);
    return (React.createElement(React.Fragment, null, loading ?
        React.createElement("div", { className: "picture-loading mt-5" },
            React.createElement(LoaderDotted, { size: FormSize.LG }))
        : error ?
            React.createElement(Redirect, { to: routes.error })
            : data &&
                React.createElement(React.Fragment, null,
                    !isViewCategory &&
                        React.createElement(HeaderCategoryComponent, { currentIdCategory: currentIdCategory, setCurrentIdCategory: setCurrentIdCategory, resetView: resetView, data: data, searchCourseName: searchCourseName }),
                    React.createElement("div", { id: currentView == DashboardViewType.LIST ? 'list' : 'grid', className: currentView == DashboardViewType.LIST ? 'row bg-white p-5 rounded-lg mb-5 border g-0 mx-2' : 'row' },
                        isViewCategory &&
                            React.createElement("div", { className: 'row mb-5' },
                                React.createElement("div", { className: 'col-md' },
                                    React.createElement("h2", { className: 'text-primary p-1 border-primary' }, t('consum.dashboard.category.title')))),
                        isViewCategory && (data === null || data === void 0 ? void 0 : data.length) > 0 &&
                            (data === null || data === void 0 ? void 0 : data.map((item, i) => React.createElement(CardCategoriesComponent, { idCategory: item.idCategory, category: item.category, imagenCategory: item.imagenCurso, categorySize: item.size, key: i, setCurrentIdCategory: setCurrentIdCategory }))),
                        !isViewCategory && currentView == DashboardViewType.CARDS && (data === null || data === void 0 ? void 0 : data.length) > 0 && (data === null || data === void 0 ? void 0 : data.filter(name => (checkCurrentIdCategory != '' ? name.idCategory == checkCurrentIdCategory : true)).map((courses, i) => courses.portalCourseDTOList.filter(name => name.title.toLowerCase().includes(searchCourseName.toLowerCase())).map((course, i2) => {
                            return React.createElement(CardFinishedCoursesComponent, { course: course, key: i2 });
                        }))),
                        !isViewCategory && currentView == DashboardViewType.LIST && (data === null || data === void 0 ? void 0 : data.length) > 0 && (data === null || data === void 0 ? void 0 : data.filter(name => (checkCurrentIdCategory != '' ? name.idCategory == checkCurrentIdCategory : true)).map((courses, i) => React.createElement(ListFinishedCoursesComponent, { list: courses.portalCourseDTOList.filter(name => name.title.toLowerCase().includes(searchCourseName.toLowerCase())) }))),
                        (data === null || data === void 0 ? void 0 : data.length) == 0 &&
                            React.createElement("div", { className: "card p-5 d-flex align-items-center my-5 shadow col-11 mx-auto rounded-xxl" },
                                React.createElement("p", { className: "text-darker-grey mb-0 font-size-18" }, t('consum.dashboard.mycatalogue.notfound'))),
                        !isViewCategory &&
                            React.createElement("div", { className: 'row mb-5' },
                                React.createElement("div", { className: 'col-md text-primary fw-bold' },
                                    React.createElement("a", { style: { textDecoration: 'underline' }, className: 'nav-link cursor-pointer', onClick: () => { resetView(); } },
                                        '< ',
                                        t('consum.dashboard.mycatalogue.returncategories'))))))));
};
