import { gql, useApolloClient, useQuery } from '@apollo/client';
export const GET_PICTURE = gql `
  query GetPicture($archive_id: Long!) {
    portal_getCoursePicture(archive_id: $archive_id)
  }
`;
export const useGetPictureQuery = (options) => {
    return useQuery(GET_PICTURE, Object.assign({ errorPolicy: 'ignore', fetchPolicy: 'cache-first' }, options));
};
export const useGetPictureQueryPromise = () => {
    const client = useApolloClient();
    return (variables, options) => client.query(Object.assign(Object.assign({ errorPolicy: 'all', fetchPolicy: 'cache-first' }, options), { query: GET_PICTURE, variables }));
};
