import { gql, useQuery } from '@apollo/client';
import { CARD_DASHBOARD_COURSES_TO_REGISTRATE } from '../apollo';
export const GET_CARDS_COURSES_TO_REGISTRATE = gql `
  ${CARD_DASHBOARD_COURSES_TO_REGISTRATE}
  query GetCoursesToRegistrate($user_id: Long!) {
    portal_categorizedCoursesToRegistrate(user_id: $user_id) {
      ...CoursesToRegistrate
    }
  }
`;
export const useCardsDashboardCoursesToRegistrateQuery = (options) => {
    return useQuery(GET_CARDS_COURSES_TO_REGISTRATE, Object.assign({ errorPolicy: 'ignore', fetchPolicy: 'cache-first', notifyOnNetworkStatusChange: true }, options));
};
