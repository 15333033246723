import React, { useContext } from 'react';
import icoDependent from 'assets/img/ico-dependent.png';
import icoRegistered from 'assets/img/ico-registered.png';
import { DashboardTabsType } from 'common-app/types/core';
import { useTranslation } from 'react-i18next';
import { namespaces } from 'i18n/i18n.constants';
import { ThemeContext } from 'core/router/logged';
export const HeaderDashboard = props => {
    const { currentTab } = useContext(ThemeContext);
    const { t } = useTranslation(namespaces.common);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "header" },
            React.createElement("div", { className: "banner catalog" },
                currentTab == DashboardTabsType.MY_COURSES &&
                    React.createElement("div", { className: "row" },
                        React.createElement("h1", null, t('consum.dashboard.mycourses')),
                        React.createElement("p", null, t('consum.dashboard.mycourses.subtitle')),
                        React.createElement("img", { src: icoRegistered, className: "d-none d-lg-block" })),
                currentTab == DashboardTabsType.MY_CATALOGUE &&
                    React.createElement("div", { className: "row" },
                        React.createElement("h1", null, t('consum.dashboard.mycatalogue')),
                        React.createElement("p", null, t('consum.dashboard.mycatalogue.subtitle')),
                        React.createElement("img", { src: icoDependent, className: "d-none d-lg-block" })),
                currentTab == DashboardTabsType.FINISHED_COURSES &&
                    React.createElement("div", { className: "row" },
                        React.createElement("h1", null, t('consum.dashboard.finishedcourses')),
                        React.createElement("p", null, t('consum.dashboard.finishedcourses.subtitle')),
                        React.createElement("img", { src: icoRegistered, className: "d-none d-lg-block" }))))));
};
