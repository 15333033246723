import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { namespaces } from "i18n/i18n.constants";
export const ChangeLanguageComponent = () => {
    const [option, setOption] = useState("");
    const { i18n } = useTranslation(namespaces.common);
    const captureOption = (e) => {
        setOption(e);
        i18n.changeLanguage(e);
    };
    return (React.createElement("form", null,
        React.createElement("select", { className: "form-select", defaultValue: "ES", onChange: (e) => captureOption(e.target.value) },
            React.createElement("option", { value: "ES" }, "Bienvenidos"),
            React.createElement("option", { value: "VL" }, "Benvinguts"))));
};
export default ChangeLanguageComponent;
