import { gql } from '@apollo/client';
export const PHONE_FIELDS = gql `
  fragment PhoneFields on PhoneDto {
    type
    prefix
    number
  }
`;
export const ADDRESS_FIELDS = gql `
  fragment AddressFields on AddressDto {
    door
    number
    countryCode
    postalCode
    stairs
    locality
    name
    floor
  }
`;
export const PERSON_FIELDS = gql `
  ${PHONE_FIELDS}
  ${ADDRESS_FIELDS}
  fragment PersonFields on PersonDto {
    id
    businessUnitId
    businessUnitName
    creationTimestamp
    timestamp
    identityDocument {
      document
      type
    }
    birthDate
    maidenName
    matrimonialRegime
    email
    externalReference
    emailAddresses
    phone {
      ...PhoneFields
    }
    phones {
      ...PhoneFields
    }
    name
    surname
    socialSecurityNumber
    sex
    streetAddress {
      ...AddressFields
    }
    streetAddresses {
      ...AddressFields
    }
    profession
    cno
    laborRelationship
    nativeCountry
    nativeProvince
    activitySector
    nationalities
  }
`;
export const USER_FIELDS = gql `
  ${PERSON_FIELDS}
  fragment UserFields on UserDto {
    id
    alias
    locked
    username
    language
    person {
      ...PersonFields
    }
  }
`;
export const CATEGORY_COMMON = gql `
  fragment CategoryCommon on PortalCategoryDTO {
    idCategory
    category
    imagenCurso
    size
  }
`;
export const CARD_DASHBOARD_COURSES_COMMON = gql `
fragment CardDashboardCoursesCommon on PortalCourseDTO {
  ID
  title
  goals
  duration
  description
  category
  sessions {
    ID
    initialDate
    finalDate
  }
  imagenCurso
}
`;
export const CARD_DASHBOARD_COURSES_TO_REGISTRATE = gql `
  ${CATEGORY_COMMON}
  ${CARD_DASHBOARD_COURSES_COMMON}
  fragment CoursesToRegistrate on PortalCategoryDTO {
    ...CategoryCommon
    portalCourseDTOList {
      ...CardDashboardCoursesCommon
    }
  }
`;
export const CARD_DASHBOARD_ENROLLMENT_COURSES = gql `
  ${CARD_DASHBOARD_COURSES_COMMON}
  fragment EnrollmentCourses on PortalCourseDTO {
    ...CardDashboardCoursesCommon
    moodleLink
    progress
    initialDate
    finalDate
    remainingDays
  }
`;
export const CARD_DASHBOARD_FINISHED_COURSES = gql `
  ${CATEGORY_COMMON}
  ${CARD_DASHBOARD_COURSES_COMMON}
  fragment FinishedCourses on PortalCategoryDTO {
    ...CategoryCommon
    portalCourseDTOList {
      ...CardDashboardCoursesCommon
      moodleLink
      progress
      estadoCurso
      estadoCursoLabel
      qualification
      passed
      finalDate
    }
  }
`;
