import React from 'react';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { namespaces } from 'i18n/i18n.constants';
import imgCourse from 'assets/img/img-course.jpg';
import { ModalDatesComponent } from './../modal/modal-dates.component';
import { ModalInfoComponent } from './../modal/modal-info.component';
import { useAuthContext } from '@isyc-react/auth/modules/context';
import { useRegistrationToCourse } from 'common/api/queries';
import { Spinner } from '@isyc-react/design/bootstrap';
import { ModalInfo } from 'common/components/modal/modalInfo';
import { useGetPictureQuery } from 'common/api/queries/get-picture';
import { AlertType } from 'common-app/types/core';
export const CardCoursesToRegistrateComponent = (props) => {
    var _a, _b;
    const { course, refresh, sizeEnrollmentCourses } = props;
    const { t, i18n } = useTranslation(namespaces.common);
    const { user } = useAuthContext();
    const [modalDatesShow, setModalDatesShow] = React.useState(false);
    const [modalInfoShow, setModalInfoShow] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertType, setAlertType] = React.useState('');
    const [alertMsg, setAlertMsg] = React.useState('');
    const checkInfo = course.description != "" || course.goals != "";
    const imagenCurso = course.imagenCurso ? course.imagenCurso : 0;
    let dataPictureBase64 = imgCourse;
    var loadingPicture = false;
    if (imagenCurso !== 0) {
        var { data: dataPicture, loading: loadingPicture, error: errorPicture, refetch: refetchPicture } = useGetPictureQuery({ variables: { archive_id: +imagenCurso } });
        dataPictureBase64 = React.useMemo(() => {
            return (dataPicture === null || dataPicture === void 0 ? void 0 : dataPicture.portal_getCoursePicture) != '' ? dataPicture === null || dataPicture === void 0 ? void 0 : dataPicture.portal_getCoursePicture : imgCourse;
        }, [dataPicture]);
    }
    const [registrationToCourse, { data, loading, error }] = useRegistrationToCourse();
    const registration = (userId, sessionId) => {
        registrationToCourse({ variables: { user_id: userId, sessionId: sessionId } }).then((d) => {
            var _a, _b, _c, _d;
            if (((_a = d.data) === null || _a === void 0 ? void 0 : _a.portal_registrationToCourse) == "OK") {
                setShowAlert(true);
                setAlertType(AlertType.SUCCESS);
                setAlertMsg(t('consum.dashboard.mycourses.ok'));
            }
            else if (((_b = d.data) === null || _b === void 0 ? void 0 : _b.portal_registrationToCourse) == "KO") {
                setShowAlert(true);
                setAlertType(AlertType.DANGER);
                setAlertMsg(t('consum.dashboard.mycourses.ko'));
            }
            else if (((_c = d.data) === null || _c === void 0 ? void 0 : _c.portal_registrationToCourse) == "SESSION KO") {
                setShowAlert(true);
                setAlertType(AlertType.DANGER);
                setAlertMsg(t('consum.dashboard.mycourses.sessionko'));
            }
            else if (((_d = d.data) === null || _d === void 0 ? void 0 : _d.portal_registrationToCourse) == "KO EXIST ENROLLMENT") {
                setShowAlert(true);
                setAlertType(AlertType.DANGER);
                setAlertMsg(t('consum.dashboard.mycourses.existenrollment'));
            }
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalInfo, { onHide: () => setShowAlert(false), refresh: refresh, show: showAlert, type: alertType, text: alertMsg }),
        React.createElement("div", { className: "col-xxl-4 col-md-6 d-flex" },
            React.createElement("div", { className: "card mb-4 col-12" },
                React.createElement("div", { className: "card-body p-0" },
                    React.createElement("div", { className: "container-picture" },
                        React.createElement("div", { className: "above" },
                            React.createElement("div", { className: "text-in-picture h-100 d-flex justify-content-end flex-column d-flex p-3" },
                                React.createElement("p", { className: "text-white mb-2 font-size-12 fw-semibold" }, course.category),
                                React.createElement("p", { className: "text-white font-size-19 fw-semibold mb-0" }, course.title))),
                        loadingPicture
                            ? React.createElement("div", { className: "picture-loading", style: { backgroundImage: 'linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.6))' } },
                                React.createElement(Spinner, { animation: 'border', className: 'ms-2 text-white' }))
                            : React.createElement("div", { className: "picture", style: { backgroundImage: 'linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.6)),url("' + dataPictureBase64 + '")' } })),
                    React.createElement("div", { className: "info px-4 pt-4" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col" },
                                React.createElement("i", { className: "mdi mdi-clock-outline text-primary bg-lighter-grey px-2 py-1 font-size-16 me-2" }),
                                " ",
                                React.createElement("span", { className: "text-dark-grey font-size-14" },
                                    course.duration,
                                    " h")),
                            course.sessions && course.sessions.length > 1 &&
                                React.createElement("div", { className: "col-auto" },
                                    React.createElement("i", { className: "mdi mdi-calendar text-primary bg-lighter-grey px-2 py-1 font-size-16 me-2" }),
                                    React.createElement("a", { className: "text-dark-grey font-size-14 cursor-pointer", onClick: () => setModalDatesShow(true) },
                                        React.createElement("u", null, t('consum.dashboard.mycatalogue.availabledates')))),
                            course.sessions && course.sessions.length == 1 &&
                                React.createElement("div", { className: "col-auto" },
                                    React.createElement("i", { className: "mdi mdi-calendar text-primary bg-lighter-grey px-2 py-1 font-size-16 me-2" }),
                                    React.createElement("a", { className: "text-dark-grey font-size-14 cursor-pointer" },
                                        React.createElement("u", null,
                                            dayjs((_a = course.sessions[0].initialDate) !== null && _a !== void 0 ? _a : '').format('DD/MM/YYYY'),
                                            " - ",
                                            dayjs((_b = course.sessions[0].finalDate) !== null && _b !== void 0 ? _b : '').format('DD/MM/YYYY')))),
                            course.sessions && course.sessions.length == 0 &&
                                React.createElement("div", { className: "col-auto" },
                                    React.createElement("i", { className: "mdi mdi-calendar text-primary bg-lighter-grey px-2 py-1 font-size-16 me-2" }),
                                    React.createElement("span", { className: "text-dark-grey font-size-14" }, t('consum.dashboard.mycatalogue.nosessions')))),
                        React.createElement("div", { className: "text-center more-info" }, checkInfo ?
                            React.createElement("a", { className: "text-dark-grey font-size-14 cursor-pointer", onClick: () => setModalInfoShow(true) },
                                React.createElement("u", null, t('consum.dashboard.moreinformation')))
                            :
                                React.createElement("p", { className: "text-dark-grey font-size-14" }, t('consum.dashboard.noinformation'))),
                        React.createElement("div", { className: "separe border-bottom" }))),
                React.createElement("div", { className: "card-footer w-100 d-flex justify-content-center bg-white border-0 my-4" },
                    (course.sessions && course.sessions.length > 1 && (sizeEnrollmentCourses >= 0 && sizeEnrollmentCourses < 2)) &&
                        React.createElement("button", { type: "button", onClick: () => setModalDatesShow(true), className: "btn btn-pill btn-outline-primary border-2 px-5 font-size-16" }, t('consum.dashboard.mycatalogue.enrol')),
                    (course.sessions && course.sessions.length == 1 && (sizeEnrollmentCourses >= 0 && sizeEnrollmentCourses < 2)) &&
                        React.createElement("button", { type: "button", onClick: () => { var _a; return registration((_a = user === null || user === void 0 ? void 0 : user.id) !== null && _a !== void 0 ? _a : 0, course.sessions[0].ID); }, className: "btn btn-pill btn-outline-primary border-2 px-5 font-size-16", disabled: loading },
                            !loading && React.createElement(React.Fragment, null, t('consum.dashboard.mycatalogue.enrol')),
                            loading && React.createElement(Spinner, { animation: 'border', className: 'ms-2', size: 'sm' })),
                    ((course.sessions && course.sessions.length == 0) || (sizeEnrollmentCourses >= 2)) &&
                        React.createElement("p", { className: "text-center m-0" }, t('consum.dashboard.mycatalogue.noavailable'))))),
        React.createElement(ModalDatesComponent, { show: modalDatesShow, onHide: () => setModalDatesShow(false), sessions: course.sessions, refresh: refresh, sizeEnrollmentCourses: sizeEnrollmentCourses }),
        React.createElement(ModalInfoComponent, { show: modalInfoShow, onHide: () => setModalInfoShow(false), course: course })));
};
