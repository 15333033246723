import { gql, useLazyQuery } from '@apollo/client';
import { USER_FIELDS } from '../apollo';
export const GET_CURRENT_USER = gql `
  ${USER_FIELDS}
  query GetCurrentUser {
    user_read_current {
      ...UserFields
    }
  }
`;
export const useLazyQueryCurrentUser = (options) => {
    return useLazyQuery(GET_CURRENT_USER, options !== null && options !== void 0 ? options : {
        errorPolicy: 'ignore',
    });
};
