import React from 'react';
import '../../assets/scss/error.style.scss';
import { SidebarLayout } from 'layout';
export const ErrorScene = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(SidebarLayout, null,
            React.createElement("div", { className: "error-scene" },
                React.createElement("div", { className: "card p-5 d-flex my-5 shadow col-auto mx-auto rounded-xxl" },
                    React.createElement("div", { className: "card-body" },
                        React.createElement("h1", { className: 'font-size-40 fw-bold text-darker-grey text-center' }, "Error"),
                        React.createElement("p", { className: 'text-darker-grey font-size-20 text-center' },
                            "La p\u00E1gina que busca ",
                            React.createElement("b", null, "no se encuentra")),
                        React.createElement("p", { className: 'text-darker-grey font-size-20 text-center mb-0' }, "Vaya a la p\u00E1gina de inicio o cont\u00E1ctenos")))))));
};
