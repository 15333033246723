import React from 'react';
import 'dayjs/locale/fr';
import { useTranslation } from 'react-i18next';
import { namespaces } from 'i18n/i18n.constants';
export const SearchComponent = (props) => {
    const { searchCourseName, setSearchCourseName } = props;
    const { t } = useTranslation(namespaces.common);
    const [searchValue, setSearchValue] = React.useState(searchCourseName);
    const searchOnEnter = (value) => {
        if (value.key == 'Enter') {
            setSearchCourseName(value.target.value);
        }
    };
    const searchOnChange = (value) => {
        setSearchValue(value);
        if (value == "") {
            setSearchCourseName(value);
        }
    };
    return (React.createElement("div", { className: 'has-search me-3' },
        React.createElement("input", { className: 'form-control', placeholder: t('consum.searchcomponent.search'), onKeyDown: (e) => searchOnEnter(e), onChange: (value) => searchOnChange(value.target.value), value: searchValue }),
        React.createElement("i", { className: 'mdi mdi-magnify' })));
};
