import React from 'react';
import icoCoursesEnrolledOn from 'assets/img/ico-courses-enrolled-on.png';
import icoCompletedCoursesOn from 'assets/img/ico-completed-courses-on.png';
import icoCatalogueOn from 'assets/img/ico-catalogue-on.png';
import icoLogoutOn from 'assets/img/ico-logout-on.png';
export const MenuPreload = () => {
    return (React.createElement("div", { className: 'preload' },
        React.createElement("img", { src: icoCoursesEnrolledOn }),
        React.createElement("img", { src: icoCompletedCoursesOn }),
        React.createElement("img", { src: icoCatalogueOn }),
        React.createElement("img", { src: icoLogoutOn })));
};
