import React from 'react';
import 'dayjs/locale/fr';
import { useTranslation } from 'react-i18next';
import { namespaces } from 'i18n/i18n.constants';
import imgCourse from 'assets/img/img-course.jpg';
import { useAuthContext } from '@isyc-react/auth/modules/context';
import { useGetPictureQuery } from 'common/api/queries/get-picture';
import { Spinner } from '@isyc-react/design/bootstrap';
export const CardCategoriesComponent = (props) => {
    const { user } = useAuthContext();
    const { idCategory, setCurrentIdCategory, imagenCategory, category, categorySize } = props;
    const { t, i18n } = useTranslation(namespaces.common);
    const imgCategory = imagenCategory ? imagenCategory : 0;
    let dataPictureBase64 = imgCourse;
    var loadingPicture = false;
    if (imgCategory !== 0) {
        var { data: dataPicture, loading: loadingPicture, error, refetch } = useGetPictureQuery({ variables: { archive_id: +imagenCategory } });
        dataPictureBase64 = React.useMemo(() => {
            return (dataPicture === null || dataPicture === void 0 ? void 0 : dataPicture.portal_getCoursePicture) != '' ? dataPicture === null || dataPicture === void 0 ? void 0 : dataPicture.portal_getCoursePicture : imgCourse;
        }, [dataPicture]);
    }
    return (React.createElement("div", { className: "col-12 col-xl-6 col-xxl-2 mb-5" },
        React.createElement("div", { className: "categorie cursor-pointer", onClick: () => setCurrentIdCategory && setCurrentIdCategory(idCategory) },
            loadingPicture
                ? React.createElement(Spinner, { animation: 'border', className: 'ms-2' })
                : React.createElement("div", { className: "image", style: { backgroundImage: 'url("' + dataPictureBase64 + '")' } }),
            React.createElement("div", { className: "text-center fw-bold mt-3" },
                category,
                " (" + categorySize + ")"))));
};
