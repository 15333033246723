import React from 'react';
import logo from 'assets/img/logo.png';
import { useLogout } from '@isyc-react/auth/modules/pods/logout';
import { Collapse } from '@isyc-react/design/bootstrap';
import { MenuPreload, Menu } from './menu';
import clsx from 'clsx';
import { routes } from 'core/router/router';
import '../../assets/scss/menu.style.scss';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '@isyc-react/auth/modules/context';
import { useTranslation } from 'react-i18next';
import { namespaces } from 'i18n/i18n.constants';
import ChangeLanguageComponent from '../../scenes/dashboard/change-language-form.component';
export const SidebarLayout = props => {
    var _a, _b, _c, _d, _e, _f;
    const pictureColor = React.useMemo(() => { var _a; return (_a = localStorage.getItem('pictureColor')) !== null && _a !== void 0 ? _a : 'bg-grey'; }, []);
    const { children, footer = true, className } = props;
    const { user } = useAuthContext();
    const { t } = useTranslation(namespaces.common);
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    React.useEffect(() => {
        if (open)
            document.body.classList.add('overflow-hidden');
        else
            document.body.classList.remove('overflow-hidden');
    }, [open]);
    const { logout } = useLogout();
    return (React.createElement(React.Fragment, null,
        React.createElement(MenuPreload, null),
        React.createElement("div", { className: "consum" },
            React.createElement("div", { className: "container-fluid" },
                React.createElement("div", { className: "row wrapper p-0" },
                    React.createElement("div", { className: "bar" },
                        React.createElement("div", { className: "start" },
                            React.createElement("div", { className: "row mx-auto justify-content-center" },
                                React.createElement("div", { className: "col-auto logo" },
                                    React.createElement("img", { src: logo, onClick: () => history.push(routes.courses) }))),
                            React.createElement("div", { className: "avatar d-none d-lg-block" },
                                (user === null || user === void 0 ? void 0 : user.userPicture) && (user === null || user === void 0 ? void 0 : user.userPicture) !== 'not_available' ?
                                    React.createElement("div", { className: "picture mx-auto mt-5 rounded-pill", style: { backgroundImage: 'url("' + (user === null || user === void 0 ? void 0 : user.userPicture) + '")' } })
                                    :
                                        React.createElement("div", { className: "picture " + pictureColor + " mx-auto mt-5 rounded-pill" },
                                            React.createElement("span", { className: "text-white font-size-75 d-flex align-items-center justify-content-center h-100" }, ((_b = (_a = user === null || user === void 0 ? void 0 : user.person) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '').charAt(0).toUpperCase())),
                                React.createElement("p", { className: "text-center font-size-16 text-black mt-4 mb-5 fw-semibold" },
                                    ((_d = (_c = user === null || user === void 0 ? void 0 : user.person) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : '').toUpperCase(),
                                    " ",
                                    ((_f = (_e = user === null || user === void 0 ? void 0 : user.person) === null || _e === void 0 ? void 0 : _e.surname) !== null && _f !== void 0 ? _f : '').toUpperCase()),
                                React.createElement(ChangeLanguageComponent, null),
                                React.createElement("hr", { className: "border-2 border-bottom border-grey opacity-50" }))),
                        React.createElement(Collapse, { in: open },
                            React.createElement("div", { id: 'collapsingNavbar', className: 'menu-collapse' },
                                React.createElement(Menu, { onEntryClick: () => setOpen(false) }))),
                        React.createElement("div", { className: 'button navbar-light' },
                            React.createElement("button", { className: clsx('navbar-toggler border-0', { collapsed: !open }), type: 'button', onClick: () => setOpen(!open), "aria-expanded": open },
                                React.createElement("span", { className: 'toggler-icon top-bar' }),
                                React.createElement("span", { className: 'toggler-icon middle-bar' }),
                                React.createElement("span", { className: 'toggler-icon bottom-bar' })))),
                    React.createElement("div", { className: 'main' },
                        React.createElement("div", { className: 'container mx-auto p-0' }, children)))),
            footer && (React.createElement("footer", null,
                React.createElement("div", { className: "container p-0" },
                    React.createElement("p", { className: "text-end" }, "@ 2023 - Consum")))))));
};
