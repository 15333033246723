import React from 'react';
import 'dayjs/locale/fr';
import { Button, Modal } from '@isyc-react/design/bootstrap';
import { useAuthContext } from '@isyc-react/auth/modules/context';
import icoModal from 'assets/img/ico-modal.png';
import { useTranslation } from 'react-i18next';
import { namespaces } from 'i18n/i18n.constants';
export const ModalInfoComponent = (props) => {
    const { course } = props;
    const { t } = useTranslation(namespaces.common);
    const { user } = useAuthContext();
    return (React.createElement(Modal, { show: props.show, size: "lg", "aria-labelledby": "contained-modal-title-vcenter", centered: true, scrollable: true },
        React.createElement(Modal.Header, { className: 'border-bottom-0 bg-white p-4' },
            React.createElement("h5", { className: 'modal-title font-size-22 text-black' }, t('consum.modalinfo.moreinfo')),
            React.createElement("button", { type: "button", className: "btn-close me-1", "aria-label": t('consum.modalinfo.close'), onClick: props.onHide })),
        React.createElement(Modal.Body, { className: 'p-0' },
            React.createElement("div", { className: 'row m-0' },
                React.createElement("div", { className: 'col px-5 pb-4' },
                    React.createElement("h2", { className: 'text-primary fw-bold border-bottom p-1 border-primary' }, t('consum.modalinfo.goals')),
                    React.createElement("p", null, course.goals),
                    React.createElement("h2", { className: 'text-primary fw-bold border-bottom p-1 border-primary' }, t('consum.modalinfo.description')),
                    React.createElement("p", null, course.description)),
                React.createElement("div", { className: 'col-auto align-self-end p-0 d-none d-lg-block' },
                    React.createElement("img", { src: icoModal })))),
        React.createElement(Modal.Footer, { className: 'd-flex justify-content-center py-4 mt-5 mt-lg-0' },
            React.createElement(Button, { className: 'btn btn-pill btn-primary border-2 px-5 font-size-16', onClick: props.onHide }, t('consum.modalinfo.close')))));
};
