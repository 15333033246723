import React from 'react';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { namespaces } from 'i18n/i18n.constants';
import { ModalDatesComponent } from './../modal/modal-dates.component';
import { ModalInfoComponent } from './../modal/modal-info.component';
import { useAuthContext } from '@isyc-react/auth/modules/context';
import { useRegistrationToCourse } from 'common/api/queries';
import { Spinner } from '@isyc-react/design/bootstrap';
import { ModalInfo } from 'common/components/modal/modalInfo';
import { TableClient } from '@isyc-react/table';
import { Components } from '@isyc-react/table/modules/base';
import '../../../assets/scss/table.style.scss';
import { AlertType } from 'common-app/types/core';
export const ListCoursesToRegistrateComponent = (props) => {
    const { list, refresh, sizeEnrollmentCourses } = props;
    const { t, i18n } = useTranslation(namespaces.common);
    const [modalDatesShow, setModalDatesShow] = React.useState(false);
    const [modalInfoShow, setModalInfoShow] = React.useState(false);
    const [sessions, setSessions] = React.useState([{
            ID: 0,
            initialDate: new Date(),
            finalDate: new Date()
        }]);
    const [course, setCourse] = React.useState({
        ID: 0,
        title: "",
        goals: "",
        duration: 0,
        description: "",
        category: "",
        sessions: sessions,
        imagenCurso: ""
    });
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertType, setAlertType] = React.useState('');
    const [alertMsg, setAlertMsg] = React.useState('');
    // const imagenCurso = course.imagenCurso && course.imagenCurso != 'Imagen no disponible' ? course.imagenCurso : imgCourse
    const { user } = useAuthContext();
    const [registrationToCourse, { data, loading, error }] = useRegistrationToCourse();
    const registration = (userId, sessionId) => {
        registrationToCourse({ variables: { user_id: userId, sessionId: sessionId } }).then((d) => {
            var _a, _b, _c, _d;
            if (((_a = d.data) === null || _a === void 0 ? void 0 : _a.portal_registrationToCourse) == "OK") {
                setShowAlert(true);
                setAlertType(AlertType.SUCCESS);
                setAlertMsg(t('consum.dashboard.mycourses.ok'));
            }
            else if (((_b = d.data) === null || _b === void 0 ? void 0 : _b.portal_registrationToCourse) == "KO") {
                setShowAlert(true);
                setAlertType(AlertType.DANGER);
                setAlertMsg(t('consum.dashboard.mycourses.ko'));
            }
            else if (((_c = d.data) === null || _c === void 0 ? void 0 : _c.portal_registrationToCourse) == "SESSION KO") {
                setShowAlert(true);
                setAlertType(AlertType.DANGER);
                setAlertMsg(t('consum.dashboard.mycourses.sessionko'));
            }
            else if (((_d = d.data) === null || _d === void 0 ? void 0 : _d.portal_registrationToCourse) == "KO EXIST ENROLLMENT") {
                setShowAlert(true);
                setAlertType(AlertType.DANGER);
                setAlertMsg(t('consum.dashboard.mycourses.existenrollment'));
            }
        });
    };
    const clickDateAvailable = (sessions) => {
        setSessions(sessions);
        setModalDatesShow(true);
    };
    const clickMoreInfo = (course) => {
        setCourse(course);
        setModalInfoShow(true);
    };
    const columns = React.useMemo(() => [
        {
            Header: t('consum.dashboard.mycatalogue.list.title').toString(),
            accessor: 'title',
            aggregate: 'count',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            Header: t('consum.dashboard.mycatalogue.list.category').toString(),
            accessor: 'category',
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            Header: t('consum.dashboard.mycatalogue.list.duration').toString(),
            accessor: 'duration',
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            Header: t('consum.dashboard.mycatalogue.list.sessions').toString(),
            accessor: 'sessions',
            disableFilters: true,
            disableSortBy: true,
            aggregate: 'uniqueCount',
            Cell: ({ value }) => {
                var _a, _b;
                return React.createElement("div", null,
                    value != null && value.length > 1 &&
                        React.createElement("div", { className: "col" },
                            React.createElement("a", { className: "text-dark-grey font-size-14 cursor-pointer", onClick: () => clickDateAvailable(value) },
                                React.createElement("u", null, t('consum.dashboard.mycatalogue.availabledates')))),
                    value != null && value.length == 1 &&
                        React.createElement("div", { className: "col" },
                            React.createElement("a", { className: "text-dark-grey font-size-14 cursor-pointer" },
                                React.createElement("u", null,
                                    dayjs((_a = value[0].initialDate) !== null && _a !== void 0 ? _a : '').format('DD/MM/YYYY'),
                                    " - ",
                                    dayjs((_b = value[0].finalDate) !== null && _b !== void 0 ? _b : '').format('DD/MM/YYYY')))),
                    value != null && value.length == 0 &&
                        React.createElement("div", { className: "col" },
                            React.createElement("span", { className: "text-dark-grey font-size-14" }, t('consum.dashboard.mycatalogue.nosessions'))));
            }
        },
        {
            Header: '',
            id: 'actions',
            accessor: item => ({ course: item, session: item.sessions }),
            disableFilters: true,
            disableSortBy: true,
            canGroupBy: false,
            Cell: ({ value }) => (React.createElement("div", { className: 'text-center' },
                React.createElement("p", { className: "mb-0" },
                    React.createElement("i", { className: "mdi mdi-information-outline text-primary font-size-20 align-middle me-2" }),
                    (value.course.description != "" || value.course.goals != "") ?
                        React.createElement("a", { className: "text-primary cursor-pointer", onClick: () => clickMoreInfo(value.course) },
                            React.createElement("u", null, t('consum.dashboard.mycatalogue.list.moreinfo')))
                        :
                            React.createElement("span", { className: "text-primary" }, t('consum.dashboard.mycatalogue.list.noinfo')))))
        },
        {
            Header: '',
            id: 'actions2',
            accessor: item => ({ course: item, session: item.sessions }),
            disableFilters: true,
            disableSortBy: true,
            canGroupBy: false,
            Cell: ({ value }) => (React.createElement("div", { className: 'text-center' }, value.session !== null && (React.createElement(React.Fragment, null,
                value.session.length > 1 && (sizeEnrollmentCourses >= 0 && sizeEnrollmentCourses < 2) &&
                    React.createElement("button", { type: "button", onClick: () => clickDateAvailable(value.session), className: "btn btn-pill btn-outline-primary border-2 px-5 font-size-16" }, t('consum.dashboard.mycatalogue.enrol')),
                value.session.length == 1 && (sizeEnrollmentCourses >= 0 && sizeEnrollmentCourses < 2) &&
                    React.createElement("button", { type: "button", onClick: () => { var _a; return registration((_a = user === null || user === void 0 ? void 0 : user.id) !== null && _a !== void 0 ? _a : 0, value.session[0].ID); }, className: "btn btn-pill btn-outline-primary border-2 px-5 font-size-16", disabled: loading },
                        !loading && React.createElement(React.Fragment, null, t('consum.dashboard.mycatalogue.enrol')),
                        loading && React.createElement(Spinner, { animation: 'border', className: 'ms-2', size: 'sm' })),
                (value.session.length == 0 || sizeEnrollmentCourses >= 2) &&
                    React.createElement("p", { className: "text-center" }, t('consum.dashboard.mycatalogue.noavailable'))))))
        }
    ], [loading]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalInfo, { onHide: () => setShowAlert(false), refresh: refresh, show: showAlert, type: alertType, text: alertMsg }),
        React.createElement(TableClient, { components: {
                Filter: (props) => React.createElement(React.Fragment, null),
                Table: (props) => React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "card-body", id: "dashboard-list" },
                        React.createElement("div", { className: "table-responsive-lg" },
                            React.createElement(Components.Table, Object.assign({}, props))))),
                Pagination: (props) => React.createElement(React.Fragment, null)
            }, disableGlobalFilter: true, disableFilters: true, columns: columns, data: list, 
            // options={{
            //   pagination: {
            //     sizes: [10, 25, 50, 100]
            //   }
            // }}
            initialState: {
                pageSize: 500,
                sortBy: [{ id: 'title', desc: false }]
            } }),
        React.createElement(ModalDatesComponent, { show: modalDatesShow, onHide: () => setModalDatesShow(false), sessions: sessions, refresh: refresh, sizeEnrollmentCourses: sizeEnrollmentCourses }),
        React.createElement(ModalInfoComponent, { show: modalInfoShow, onHide: () => setModalInfoShow(false), course: course })));
};
