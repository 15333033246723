import React from 'react';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { namespaces } from 'i18n/i18n.constants';
import { ModalInfoComponent } from './../modal/modal-info.component';
import { TableClient } from '@isyc-react/table';
import { Components } from '@isyc-react/table/modules/base';
import { useGetLinkAccessMoodleQueryPromise } from 'common/api/queries/access-to-moodle';
export const ListEnrollmentCoursesComponent = (props) => {
    const { list } = props;
    const { t, i18n } = useTranslation(namespaces.common);
    const [modalInfoShow, setModalInfoShow] = React.useState(false);
    const getLinkAccessMoodle = useGetLinkAccessMoodleQueryPromise();
    const accessMoodle = (moodle_id) => {
        getLinkAccessMoodle({ moodle_id }).then((response) => {
            if (response.data && response.data.portal_accessMoodle != "") {
                window.open(response.data.portal_accessMoodle, '_blank', 'noopener,noreferrer');
            }
        });
    };
    const [course, setCourse] = React.useState({
        ID: 0,
        title: "",
        goals: "",
        duration: 0,
        description: "",
        category: "",
        sessions: [{
                ID: 0,
                initialDate: new Date(),
                finalDate: new Date()
            }],
        imagenCurso: "",
        moodleLink: 0,
        progress: 0,
        initialDate: new Date(),
        finalDate: new Date(),
        remainingDays: 0
    });
    const clickMoreInfo = (course) => {
        setCourse(course);
        setModalInfoShow(true);
    };
    const columns = React.useMemo(() => [
        {
            Header: t('consum.dashboard.mycourses.list.title').toString(),
            accessor: 'title',
            aggregate: 'count',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            Header: t('consum.dashboard.mycourses.list.category').toString(),
            accessor: 'category',
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            Header: t('consum.dashboard.mycourses.list.duration').toString(),
            accessor: 'duration',
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            Header: t('consum.dashboard.mycourses.list.sessions').toString(),
            accessor: item => ({ course: item }),
            disableFilters: true,
            disableSortBy: true,
            aggregate: 'uniqueCount',
            Cell: ({ value }) => {
                var _a, _b;
                return (React.createElement("div", null,
                    React.createElement("div", { className: "col" },
                        React.createElement("a", { className: "text-dark-grey font-size-14" },
                            React.createElement("u", null,
                                dayjs((_a = value.course.initialDate) !== null && _a !== void 0 ? _a : '').format('DD/MM/YYYY'),
                                " - ",
                                dayjs((_b = value.course.finalDate) !== null && _b !== void 0 ? _b : '').format('DD/MM/YYYY'))))));
            }
        },
        {
            Header: t('consum.dashboard.mycourses.list.remainingDays').toString(),
            accessor: 'remainingDays',
            disableFilters: true,
            disableSortBy: true,
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            Header: t('consum.dashboard.mycourses.list.progress').toString(),
            accessor: 'progress',
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value !== null && value !== void 0 ? value : '-'),
        },
        {
            Header: '',
            id: 'actions',
            accessor: item => ({ course: item }),
            disableFilters: true,
            disableSortBy: true,
            canGroupBy: false,
            Cell: ({ value }) => (React.createElement("div", { className: 'text-center' },
                React.createElement("p", { className: "mb-0" },
                    React.createElement("i", { className: "mdi mdi-information-outline text-primary font-size-20 align-middle me-2" }),
                    (value.course.description != "" || value.course.goals != "") ?
                        React.createElement("a", { className: "text-primary cursor-pointer", onClick: () => clickMoreInfo(value.course) },
                            React.createElement("u", null, t('consum.dashboard.mycourses.list.moreinfo')))
                        :
                            React.createElement("span", { className: "text-primary" }, t('consum.dashboard.mycourses.list.noinfo')))))
        },
        {
            Header: '',
            id: 'actions2',
            accessor: item => ({ course: item }),
            disableFilters: true,
            disableSortBy: true,
            canGroupBy: false,
            Cell: ({ value }) => (React.createElement("div", { className: 'text-center' },
                React.createElement("button", { type: "button", onClick: () => accessMoodle(value.course.moodleLink), className: "btn btn-pill btn-outline-primary border-2 px-4 font-size-14 fw-bold" }, t('consum.dashboard.mycourses.access'))))
        },
    ], []);
    return (React.createElement(React.Fragment, null,
        React.createElement(TableClient, { components: {
                Filter: (props) => React.createElement(React.Fragment, null),
                Table: (props) => React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "card-body", id: "dashboard-list" },
                        React.createElement("div", { className: "table-responsive-lg" },
                            React.createElement(Components.Table, Object.assign({}, props))))),
                Pagination: (props) => React.createElement(React.Fragment, null)
            }, disableGlobalFilter: true, disableFilters: true, columns: columns, data: list, 
            // options={{
            //   pagination: {
            //     sizes: [10, 25, 50, 100]
            //   }
            // }}
            initialState: {
                pageSize: 500,
                sortBy: [{ id: 'title', desc: false }]
            } }),
        React.createElement(ModalInfoComponent, { show: modalInfoShow, onHide: () => setModalInfoShow(false), course: course })));
};
