import React from 'react';
import { Modal, Button } from '@isyc-react/design/bootstrap';
import { useTranslation } from 'react-i18next';
import { namespaces } from 'i18n/i18n.constants';
import { AlertType } from 'common-app/types/core';
export const ModalInfo = props => {
    const { text, type, refresh } = props;
    const { t } = useTranslation(namespaces.common);
    const closeModal = () => {
        props.onHide();
        if (type == AlertType.SUCCESS) {
            if (refresh)
                refresh();
        }
    };
    return (React.createElement(Modal, Object.assign({}, props, { size: 'lg', "aria-labelledby": 'contained-modal-title-vcenter', centered: true, backdrop: "static", keyboard: false }),
        React.createElement(Modal.Header, { className: 'border-bottom-0 bg-white p-4' },
            React.createElement("h2", { className: "text-center " + type }, text)),
        React.createElement(Modal.Footer, null,
            React.createElement(Button, { onClick: closeModal }, t('consum.modalinfo.close')))));
};
