import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'core/router/router';
import clsx from 'clsx';
import { useAuthContext } from '@isyc-react/auth/modules/context';
import { useTranslation } from 'react-i18next';
import { namespaces } from 'i18n/i18n.constants';
import { DashboardTabsType } from 'common-app/types/core';
import { useLogout } from '@isyc-react/auth/modules/pods/logout';
import { ThemeContext } from 'core/router/logged';
export const Menu = (props) => {
    const { onEntryClick } = props;
    const { currentTab, setCurrentTab } = useContext(ThemeContext);
    const { pathname } = useLocation();
    const { user } = useAuthContext();
    const { t } = useTranslation(namespaces.common);
    const history = useHistory();
    const { logout } = useLogout();
    const redirect = React.useCallback((route, tab) => {
        history.push(route);
        onEntryClick && onEntryClick();
        tab != null && setCurrentTab(tab);
    }, [history, onEntryClick]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "menu collapse show", id: "collapsingMenu" },
            React.createElement("ul", { className: 'nav flex-column mt-3 mb-auto' },
                React.createElement("li", { className: clsx('nav-item courses-enrolled', { active: currentTab == DashboardTabsType.MY_COURSES }) },
                    React.createElement("a", { className: clsx('nav-link', { 'cursor-pointer': pathname == routes.courses && currentTab !== DashboardTabsType.MY_COURSES }), onClick: (e) => {
                            e.preventDefault();
                            redirect(routes.courses, DashboardTabsType.MY_COURSES);
                        } },
                        React.createElement("div", { className: "icon" }),
                        t('consum.dashboard.mycourses').toUpperCase())),
                React.createElement("li", { className: clsx('nav-item catalogue', { active: currentTab == DashboardTabsType.MY_CATALOGUE }) },
                    React.createElement("a", { className: clsx('nav-link', { 'cursor-pointer': pathname == routes.courses && currentTab !== DashboardTabsType.MY_CATALOGUE }), onClick: (e) => {
                            e.preventDefault();
                            redirect(routes.courses, DashboardTabsType.MY_CATALOGUE);
                        } },
                        React.createElement("div", { className: "icon" }),
                        t('consum.dashboard.mycatalogue').toUpperCase())),
                React.createElement("li", { className: clsx('nav-item completed-courses', { active: currentTab == DashboardTabsType.FINISHED_COURSES }) },
                    React.createElement("a", { className: clsx('nav-link', { 'cursor-pointer': pathname == routes.courses && currentTab !== DashboardTabsType.FINISHED_COURSES }), onClick: (e) => {
                            e.preventDefault();
                            redirect(routes.courses, DashboardTabsType.FINISHED_COURSES);
                        } },
                        React.createElement("div", { className: "icon" }),
                        t('consum.dashboard.finishedcourses').toUpperCase()))),
            React.createElement("div", { className: "end" },
                React.createElement("hr", { className: 'border-2 border-bottom border-grey opacity-50 w-100' }),
                React.createElement("ul", { className: 'nav' },
                    React.createElement("li", { className: "nav-item logout" },
                        React.createElement("a", { className: 'nav-link cursor-pointer', onClick: () => logout() },
                            React.createElement("div", { className: "icon" }),
                            t('consum.dashboard.logout'))))))));
};
