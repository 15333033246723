export var DashboardTabsType;
(function (DashboardTabsType) {
    DashboardTabsType[DashboardTabsType["MY_COURSES"] = 0] = "MY_COURSES";
    DashboardTabsType[DashboardTabsType["MY_CATALOGUE"] = 1] = "MY_CATALOGUE";
    DashboardTabsType[DashboardTabsType["FINISHED_COURSES"] = 2] = "FINISHED_COURSES";
})(DashboardTabsType || (DashboardTabsType = {}));
export var DashboardViewType;
(function (DashboardViewType) {
    DashboardViewType[DashboardViewType["CARDS"] = 1] = "CARDS";
    DashboardViewType[DashboardViewType["LIST"] = 2] = "LIST";
})(DashboardViewType || (DashboardViewType = {}));
export var AlertType;
(function (AlertType) {
    AlertType["SUCCESS"] = "text-success";
    AlertType["DANGER"] = "text-danger";
})(AlertType || (AlertType = {}));
