import { gql, useQuery } from '@apollo/client';
import { CARD_DASHBOARD_FINISHED_COURSES } from '../apollo';
export const GET_CARDS_DASHBOARD_FINISHED_COURSES = gql `
  ${CARD_DASHBOARD_FINISHED_COURSES}
  query GetCardsDashboardFinishedCourses($user_id: Long!) {
    portal_categorizedFinishedCourses(user_id: $user_id) {
      ...FinishedCourses
    }
  }
`;
export const useCardsDashboardFinishedCoursesQuery = (options) => {
    return useQuery(GET_CARDS_DASHBOARD_FINISHED_COURSES, Object.assign({ errorPolicy: 'ignore', fetchPolicy: 'cache-first', notifyOnNetworkStatusChange: true }, options));
};
